<template>
  <div class="mt-5">
    <v-row>
      <v-col cols="12" md="3" class="info-content">
        <labelled-info label="Produtor">
          {{ producerData.produtor }}
        </labelled-info>
      </v-col>
      <v-col
        v-if="canSeeProductiveUnits && hasProductiveUnit"
        cols="12"
        md="3"
        class="info-content"
      >
        <labelled-info label="Unidade Produtiva">
          {{ productiveUnitName }}
        </labelled-info>
      </v-col>
    </v-row>

    <v-card class="mt-5" color="grey lighten-3">
      <v-card-text class="py-10">
        <div class="d-flex flex-column justify-center align-center fill">
          <v-icon color="accept" size="56">mdi-checkbox-marked-circle</v-icon>
          <h1 class="mt-7 primary-text--text text-responsible">
            Informações registradas com sucesso!
          </h1>
          <btn-link class="mt-3" @click="$router.push('/')"
            >Retornar à pagina inicial
          </btn-link>
        </div>
      </v-card-text>
    </v-card>

    <v-card class="my-5 pb-7" color="grey lighten-3">
      <v-card-text>
        <div class="d-flex programs-content">
          <div
            class="mt-10 flex-1"
            v-if="canSeeCardAbrBci && hasProductiveUnit"
          >
            <h3 class="mb-5 primary-text--text text-responsible">
              Programa ABR/BCI
            </h3>
            <v-card
              class="d-flex flex-column justify-space-between info-main-content"
            >
              <v-card-text class="py-7 wish-participate-content ">
                <p class="ma-0 primary-text--text ">
                  <strong>Deseja participar do Programa ABR?</strong>
                </p>
                <p class="secondary-text--text">{{ acceptedAbrLabel }}</p>

                <p class="ma-0 mt-5 primary-text--text">
                  <strong>Deseja participar do Licenciamento BCI?</strong>
                </p>
                <p class="secondary-text--text">{{ acceptedBciLabel }}</p>
              </v-card-text>
              <v-card-actions class="mb-4">
                <v-btn
                  :disabled="!acceptedAbr"
                  color="primary"
                  block
                  class="donwload-button"
                  @click="openTermAbr"
                  ><v-icon class="mr-3">mdi-cloud-download</v-icon>Download do
                  Termo de Adesão do ABR/BCI
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
          <div v-if="!canNotSeeSbrhviCard" class=" mt-10 flex-1">
            <h3 class="mb-5 primary-text--text text-responsible">
              Programa SBRHVI
            </h3>
            <v-card
              class="d-flex flex-column justify-space-between  info-main-content"
            >
              <v-card-text class="py-7">
                <v-alert
                  v-if="canSeeAlertPreviousSbrhvi"
                  class="mt-3"
                  type="info"
                >
                  O Produtor/Grupo já é participante SBRHVI.
                </v-alert>
                <div v-else class="wish-participate-content ">
                  <p class="ma-0 primary-text--text  ">
                    <strong>Deseja participar do Licenciamento SBRHVI?</strong>
                  </p>
                  <p class="secondary-text--text  ">
                    {{ acceptedSbrhviLabel }}
                  </p>
                </div>
              </v-card-text>
              <v-card-actions class="mb-4">
                <v-btn
                  :disabled="!files.termo_sbrhvi || files.termo_sbrhvi == ''"
                  color="primary"
                  block
                  class="donwload-button"
                  @click="openTermSbrHvi"
                  ><v-icon class="mr-3">mdi-cloud-download</v-icon>
                  Download do Termo de Adesão do SBRHVI
                </v-btn>
              </v-card-actions>
            </v-card>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import LabelledInfo from "@/modules/core/views/components/LabelledInfo";
import BtnLink from "@/modules/core/views/components/BtnLink";
import { InvitedModes } from "@/modules/invited/enums/invited-mode.enum";
import { InviteStatus } from "@/modules/core/enums/invite-status.enum";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "TermConfirmation",
  components: {
    LabelledInfo,
    BtnLink
  },
  data: () => ({
    producerData: {},
    productiveUnitData: {},
    files: {
      termo_sbrhvi: "",
      termo_abr_bci: ""
    }
  }),
  async mounted() {
    await this.getDetail();
    if (this.canSeeCardAbrBci) {
      await this.getProductiveUnitDetail();
    }
    if (this.acceptedSbrhvi || this.acceptedAbr) {
      this.fetchTermsUrls();
    }
  },
  computed: {
    ...mapGetters({
      inviteDetail: "invited/getInviteDetail",
      inviteMode: "invited/getInviteMode"
    }),

    canSeeAlertPreviousSbrhvi() {
      return [
        InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_ACCEPTED.id,
        InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_ACCEPTED.id,
        InvitedModes.ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED.id
      ].includes(this.inviteMode);
    },

    canSeeCardAbrBci() {
      return [
        InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_UNACCEPTED.id,
        InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_ACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_ACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED.id,
        InvitedModes.ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED.id
      ].includes(this.inviteMode);
    },

    canSeeProductiveUnits() {
      return [
        InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_UNACCEPTED.id,
        InvitedModes.ABRBCI_WITH_SBRHVI_LEADER_ACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_ACCEPTED.id,
        InvitedModes.ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED.id,
        InvitedModes.ABRBCI_WITH_SBRHVI_EXCEPTION_ACCEPTED.id
      ].includes(this.inviteMode);
    },

    canNotSeeSbrhviCard() {
      return (
        [
          InvitedModes.ABRBCI_ONLY_SBRHVI_EXCEPTION_ACCEPTED.id,
          InvitedModes.ABRBCI_ONLY_SBRHVI_LEADER_UNACCEPTED.id
        ].includes(this.inviteMode) && !this.acceptedSbrhvi
      );
    },
    acceptedSbrhvi() {
      return (
        this.producerData?.status_sbrhvi?.id === InviteStatus.INVITE_ACCEPTED.id
      );
    },
    acceptedSbrhviLabel() {
      return this.acceptedSbrhvi ? "Sim" : "Não";
    },
    hasProductiveUnit() {
      return this.productiveUnitData?.unidades_produtivas?.length;
    },
    productiveUnit() {
      return this.hasProductiveUnit ? this.getCurrentProductiveUnit() : {};
    },
    productiveUnitName() {
      return this.productiveUnit.nome ?? "";
    },
    acceptedAbr() {
      return (
        this.productiveUnit?.status_abr?.id === InviteStatus.INVITE_ACCEPTED.id
      );
    },
    acceptedAbrLabel() {
      return this.acceptedAbr ? "Sim" : "Não";
    },
    acceptedBci() {
      return (
        this.productiveUnit?.status_bci?.id === InviteStatus.INVITE_ACCEPTED.id
      );
    },
    acceptedBciLabel() {
      return this.acceptedBci ? "Sim" : "Não";
    }
  },
  methods: {
    ...mapActions("invited", ["fetchInviteByInvitedIdAndType"]),
    ...mapActions("invite", ["fetchProductiveUnit", "getTermsUrls"]),
    ...mapActions(["toggleSnackbar"]),

    async fetchInviteDetail() {
      const { produtor_id, tipo_produtor } = this.inviteDetail;

      return await this.fetchInviteByInvitedIdAndType({
        invitedId: produtor_id,
        type: tipo_produtor
      });
    },

    async fetchProductiveUnitDetail() {
      const {
        produtor_id,
        tipo_produtor,
        unidade_produtiva_id
      } = this.inviteDetail;

      return await this.fetchProductiveUnit({
        produtor_id,
        tipo_produtor,
        unidade_produtiva_id
      });
    },

    async fetchTermsUrls() {
      const {
        produtor_id,
        tipo_produtor,
        unidade_produtiva_id
      } = this.inviteDetail;

      try {
        const response = await this.getTermsUrls({
          produtor_id,
          tipo_produtor,
          abr_bci: unidade_produtiva_id,
          sbrhvi: this.acceptedSbrhvi
        });

        this.files = response.files;
        this.toggleSnackbar({
          text: response.detail ?? this.$t("text.erro_interno"),
          type: "success"
        });
      } catch (error) {
        this.toggleSnackbar({
          text: error.response?.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    async getDetail() {
      try {
        this.producerData = await this.fetchInviteDetail();
      } catch (error) {
        this.toggleSnackbar({
          text: error.response?.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    async getProductiveUnitDetail() {
      try {
        this.productiveUnitData = await this.fetchProductiveUnitDetail();
      } catch (error) {
        this.toggleSnackbar({
          text: error.response?.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    getCurrentProductiveUnit() {
      return this.productiveUnitData.unidades_produtivas.find(
        productiveUnit =>
          productiveUnit.id === this.inviteDetail.unidade_produtiva_id
      );
    },

    openTermAbr() {
      if (!this.files?.termo_abr_bci) {
        return;
      }
      window.open(this.files.termo_abr_bci, "_blank");
    },

    openTermSbrHvi() {
      if (!this.files?.termo_sbrhvi) {
        return;
      }
      window.open(this.files.termo_sbrhvi, "_blank");
    }
  }
};
</script>
<style lang="scss" scoped>
.v-card {
  height: 90%;
}

.programs-content {
  justify-content: center;
  gap: 16px;
  padding: 0 8px;

  .donwload-button {
    gap: 4px;
  }
}
@media screen and (max-width: 960px) {
  .info-content {
    display: flex;
    justify-content: center;
    text-align: center;
  }
  .text-responsible {
    text-align: center;
  }

  .info-main-content {
    padding: 0 8px;
  }

  .programs-content {
    flex-direction: column;

    .wish-participate-content {
      text-align: center;
    }

    .donwload-button {
      white-space: normal;
      height: auto !important;
      padding: 12px !important;
      width: 100%;

      ::v-deep .v-btn__content {
        width: 100%;
      }
    }
  }
}
</style>
